.item {
  background-color: #f6f8fc;
}

.item:hover {
  background-color: #e8ebf1;
}

.menu-item-button {
  color: var(--headings-color);
}

:active.menu-item-button {
  color: var(--headings-color) !important;
  background-color: #cdd2d5 !important;
}

:hover.menu-item-button {
  color: var(--headings-color) !important;
  background-color: #cdd2d5 !important;
}

:focus-visible.menu-item-button {
  color: var(--headings-color) !important;
  background-color: #cdd2d5 !important;
}
