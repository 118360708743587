$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

.user-tile-popover-header {
  margin-bottom: 0.5rem;
}

.user-tile-popover-subtext {
  font-size: 16px !important;
}

.navbar {
  transition: background-color 0.3s ease-in;
}

.navbar .user-toggle {
  transition: color 0.3s ease-in;
}

.navbar-search {
  background: white;
  position: sticky;
  top: 0;
  min-width: 12rem;
}

.jh-breadcrumb {
  background-color: var(--active-tab-bg-color) !important;
  margin-bottom: 0;
}

.jh-breadcrumb li,
.jh-breadcrumb a {
  color: #0008 !important;
}

.jh-breadcrumb a.breadcrumb-item:hover {
  text-decoration: none !important;
  color: #000c !important;
}

.jh-breadcrumb a.breadcrumb-item:hover span {
  text-decoration: underline;
}

.jh-btn-secondary {
  background-color: transparent !important;
  color: #0d1553 !important;
  border-color: transparent !important;
  transition: all linear 0.3s;
}

.jh-btn-secondary:active,
.jh-btn-secondary:hover {
  background-color: #f4f7fa !important;
  color: #0d1553 !important;
  border-color: #becbdf !important;
}

.jh-btn-primary {
  background-color: #333 !important;
  border-color: transparent !important;
  color: #ffffff !important;
}

.jh-btn-primary:focus {
  border-color: #555 !important;
  box-shadow: 0 0 0 0.2rem rgba(96, 96, 96, 0.5) !important;
}

.jh-btn-primary:hover {
  background-color: #444 !important;
}

.cursor-not-allowed:hover {
  cursor: not-allowed;
}

.color-pop {
  color: var(--primary-color);
}

.user-menu .dropdown-item-text {
  white-space: nowrap;
}

.warming {
  --logo-left-boundary: calc((100vw - var(--loading-logo-width)) / 2);
  position: fixed;
  top: 11rem;
  left: calc(var(--logo-left-boundary) + 193px);
  transform-origin: 50% 85%;
  font-size: 128px;
  text-shadow:
    0 -0.5rem 2rem #f21b00,
    0 0.5rem 2rem #fff250;
  opacity: 0;
  transform: translate(-50%, -100%) scale(0);
  transition: all 1s ease-out;
}

.warming.warm-up {
  opacity: 1;
  transform: translate(-50%, -100%) scale(1);
}

.user-toggle.dropdown-toggle::after {
  vertical-align: super;
}

.jh-page-content {
  padding: 1rem 1.5rem;
  min-width: calc(100vw - var(--scrollbar-width));
}

.jh-page-content.maximized {
  z-index: 1000;
  background: var(--main-bg-color);
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

.jh-page-content .minimizer {
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 1001;
  padding: 0.5rem;
  display: flex;
}

.z-0 {
  z-index: 0;
}

.jh-max-height {
  max-height: calc(100vh - 3rem);
  overflow: auto;
}

.action-buttons::before {
  content: "";
  border-left: 1px solid $gray-600;
  padding-left: 0.75rem;
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.jh-action-icon {
  background: none;
  color: var(--primary-color);
  border: none;
  width: calc(1.5em + 0.75rem + 2px); /* form-control height */
  height: calc(1.5em + 0.75rem + 2px); /* form-control height */
  padding: 0;
  text-align: center;
  box-sizing: border-box;
}

.jh-action-icon:first-child {
  margin-left: 0;
}

.jh-action-icon > svg {
  font-size: 1.25rem;
  transition: font-size 0.1s ease-in;
}

.jh-action-icon::after {
  display: none;
}

.jh-action-icon:hover,
.jh-action-icon:active,
.jh-action-icon:focus,
.show > .jh-action-icon {
  color: #2b3fde !important;
  background-color: transparent !important;
}

.jh-action-icon:disabled,
.jh-action-icon:disabled:hover {
  background: none !important;
  color: gray !important;
}

.nav-link-icon {
  display: inline-block;
  width: 2em;
  text-align: center;
}

.nav-link-text {
  margin-left: 0.5em;
}

.jh-date-picker .react-date-picker__wrapper {
  border: none;
  flex-direction: row-reverse;
}

.jh-date-picker .react-date-picker__calendar-button {
  padding: 0;
}

.jh-date-picker .react-date-picker__wrapper input {
  color: #6c757d;
  transition: color ease-in 0.1s;
}

/* prevent the hidden date input overflowing the page. */
.jh-date-picker .react-date-picker__wrapper input[type="date"] {
  width: 0;
}

.jh-date-picker:hover .react-date-picker__wrapper input {
  color: black;
}

.logo-icon {
  height: 3em;
  padding-bottom: 1em;
}

.dashboard-header-text {
  font-size: 1.2rem;
  font-weight: 600;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jh-action-icons {
  display: flex;
  gap: 0.25rem;
  transition: gap 0.1s ease-in;
  flex-shrink: 0;
}

.modern-crumbs {
  .jh-action-icon {
    color: $gray-400;

    > svg {
      font-size: 1rem;
    }
  }

  .jh-action-icon:hover,
  .jh-action-icon:active,
  .jh-action-icon:focus,
  .show > .jh-action-icon {
    color: $white !important;
  }

  .jh-date-picker .react-date-picker__wrapper input {
    color: $gray-500;
  }

  .jh-date-picker:hover .react-date-picker__wrapper input {
    color: $white;
  }
}

@media print {
  .navbar,
  .popover,
  .sidebar-toggle,
  .dashboard-filters,
  .tooltip,
  .jh-chart-info,
  .jh-chart-filters {
    display: none !important;
  }

  html,
  body,
  #root {
    font-size: 14px;
  }

  .content {
    margin: 0;
    max-width: initial;
    flex: 1;
  }

  .jh-max-height {
    max-height: inherit;
  }

  .react-grid-item {
    display: block;
    position: unset !important;
    width: auto !important;
    height: auto !important;
    transform: unset !important;
    margin-bottom: 1em;
    page-break-inside: avoid;
  }

  .jh-chart-content {
    display: flex;
    justify-content: center;
  }

  @page {
    size: landscape;
  }
}

/* .modern */

.hover-underline-within {
  text-decoration: none !important;

  &:hover .hover-underline {
    text-decoration: underline;
  }
}

.layout {
  position: relative;
  min-height: calc(100vh - var(--scrollbar-height));
  width: fit-content; /* this allows the navbars to be sticky x 0 */
}

.app-nav {
  position: sticky;
  left: 0;
  z-index: 102;
  height: 3rem;
}

.navbar {
  width: calc(100vw - var(--scrollbar-width));

  button.navbar-brand {
    border: 0 !important;
    display: block;

    &::after {
      transition: all 0.2s ease-in;
      border-top-color: rgba(0, 0, 0, 55%);
    }

    &:hover::after,
    &:active::after,
    &:focus::after {
      border-top-color: black;
    }
  }

  &.navbar-dark {
    button.navbar-brand {
      &::after {
        border-top-color: rgba(255, 255, 255, 55%);
      }

      &:hover::after,
      &:active::after,
      &:focus::after {
        border-top-color: white;
      }
    }
  }

  a.active {
    font-weight: 500;
  }
}

.modern-crumbs,
.modern-tabs {
  background: $gray-300;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  font-size: 15px;
  height: var(--crumbar-height);
  z-index: 101;

  .btn {
    white-space: nowrap;
    flex-shrink: 0;
  }

  &.navbar-dark {
    background: $gray-800;
  }

  padding: 0 0.75rem;

  ol {
    margin-bottom: 0;
    flex-shrink: 1;
    min-width: 0;
    margin-right: 1rem;

    li.nav-item {
      flex-shrink: 1;
      min-width: 0;
    }

    .breadcrumb-item::before {
      color: $gray-600;
    }
  }

  .react-date-picker {
    font-size: 15px;
  }
}

/* padding in lieu of action bar on some admin pages, to support sticky table headers */
.action-bar-padding {
  background: var(--main-bg-color);
  position: sticky;
  top: var(--crumbar-height);
  left: 0;
  height: 1rem;
  z-index: 100;
}

.navbar-dark .btn-transparent {
  color: $gray-300;

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
}

.modern-tabs {
  top: var(--crumbar-height);
  height: var(--tabbar-height);
  z-index: 100;

  /* this makes the margin beneath the tab bar opaque for sticky scrolling */
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: calc(100vw - var(--scrollbar-width));
    top: var(--tabbar-height);
    height: 1rem;
    background-color: var(--main-bg-color);
  }
}

.modern-footer {
  height: 4rem;

  .footer-inner {
    height: 2rem;
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;

    .footer-inner-inner {
      position: sticky;
      left: 0;
      width: calc(100vw - var(--scrollbar-width));
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .build-info {
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.5rem;
    color: #999;
    position: absolute;
    right: 1rem;
    bottom: 0;
  }
}

#property-picker,
#property-group-picker,
#properties-picker {
  border: 1px solid $gray-300;
  font-size: 14px;

  &.btn-dark {
    border-color: $gray-700;
  }
}

#property-group-picker {
  border-right-width: 0;
}

#properties-picker {
  border-left-color: $gray-200;
}

.gap-2 {
  gap: 0.5rem;
}

.org-title-logo {
  color: black;

  &.dark {
    color: white;
  }
}

.org-logo {
  align-self: flex-start;
}

.org-logo .dropdown-toggle {
  max-width: calc(100% - 1em);
}

.nav-item .fa-user-alien {
  color: green;
}
