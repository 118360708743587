.button-th {
  color: #404253;
  font-weight: 600;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding-left: -20px;
}

.button-th:focus {
  outline: none;
}

.icon-th {
  padding-left: 5px;
  padding-top: 5px;
}

.jh-crumbar-like {
  z-index: 100;
  background-color: var(--main-bg-color);
  width: var(--content-width);
}

.reports-table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 0;
  width: 100%;

  thead {
    tr {
      th {
        position: sticky;
        top: calc(var(--crumbar-height) + var(--actionbar-height));
        z-index: 1;
        border-bottom: 1px solid black;
        background-color: var(--main-bg-color);
      }
    }
  }

  tbody {
    tr {
      th,
      td {
        border-bottom: 1px solid #dee2e6;
      }
      th {
        font-weight: 500;
      }
    }
  }
}
