.property-upload-icon {
  color: #777777;
  border-color: #777777;
}

.jh-table-with-selection-row-selected .property-upload-icon {
  color: #444c55;
  border-color: #444c55;
}

.jh-table-with-selection-row-selected .property-upload-icon:hover {
  color: #ffffff;
  background-color: #444c55;
}
