.finance-screen-main {
  display: flex;
  flex-direction: column;
}

.finance-table-wrapper {
  position: relative;
}

.finance-table {
  white-space: nowrap;
  font-size: 0.75rem;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.finance-table th,
.finance-table td {
  padding: 0 0.5rem;
}

.finance-table th.indented {
  padding-left: 1.5rem;
}

.finance-table th.depth-0 {
  padding-left: 0rem;
}

.finance-table th.depth-1 {
  padding-left: 0.5rem;
}

.finance-table th.depth-2 {
  padding-left: 1rem;
}

.finance-table th.depth-3 {
  padding-left: 1.5rem;
}

.finance-table th.depth-4 {
  padding-left: 2rem;
}

.finance-table th.depth-5 {
  padding-left: 2.5rem;
}

.finance-table th.depth-6 {
  padding-left: 3rem;
}

.finance-table th.depth-7 {
  padding-left: 3.5rem;
}

.finance-table th.depth-8 {
  padding-left: 4rem;
}

.finance-table th.depth-9 {
  padding-left: 4.5rem;
}

.finance-table thead tr {
}

.finance-table thead th {
  font-weight: 0600;
  text-align: right;
  padding-top: 2px;
  padding-bottom: 1px;
  border: 0;
  /*border-right: 1px solid var(--border-color);*/
  border-bottom: 1px solid black;
  background-color: var(--main-bg-color);
  position: sticky;
  top: 0;
}

.finance-table thead th:last-child,
.finance-table thead th.br-0 {
  border-right-width: 0;
}

.finance-table tbody th {
  font-weight: 0400;
}

.finance-table tbody th:first-child {
  text-align: right;
}

.finance-table tbody tr.clickable {
  cursor: pointer;
}

/*.finance-table tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}*/

.finance-table tbody tr:hover {
  background-color: #eaf8f6;
}

.finance-table tbody tr.total-row th,
.finance-table tbody tr.header-row th {
  font-weight: 0600;
}

.finance-table tbody tr.total-row th.node-name {
  text-decoration: underline;
}

.finance-table tbody td {
  text-align: right;
}

.finance-table thead th.delta-cell {
  text-align: left;
}

.finance-table tbody td.delta-cell {
  text-align: left;
  font-weight: 0400;
}

.finance-table a {
  color: inherit;
}

.finance-table a:hover,
.finance-table a:focus {
  color: var(--primary-color);
}

/*.finance-table tbody td.delta-plus {
  color: #080;
}

.finance-table tbody td.delta-minus {
  color: #800;
}*/

.finance-table tbody tr.total-row td {
  border-top: 1px solid black;
  font-weight: 0600;
}

.finance-filter {
  cursor: pointer;
}

.print-finance-footnote {
  display: none;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #333;
}

@media print {
  .finance-table a {
    text-decoration: none;
  }
  .finance-screen-main .container-fluid {
    padding: 0;
  }

  .print-finance-footnote {
    display: block;
  }

  .breakout-close,
  .finance-filter {
    display: none !important;
  }

  .finance-table thead th {
    position: inherit;
    border: none;
    border-bottom: 1px solid black;
    /*background-color: #eee;
    print-color-adjust: exact;*/ /* so the header background prints too */
  }
}
