.jh-table {
  background-color: var(--card-bg-color);
  border-collapse: separate;
  border-spacing: 0;
}

.jh-table-row:hover {
  cursor: pointer;
  background-color: var(--primary-color-faded);
}

.jh-table-no-results {
  text-align: center;
}

.jh-table thead th {
  top: 0;
  position: sticky;
  line-height: 1.1;
  vertical-align: middle !important;
  font-weight: 600;
  border-top: none;
  background-color: var(--card-bg-color);
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.jh-table thead th:last-child {
  border-right: none;
}

.jh-table thead th.jh-table-empty-header {
  border: none;
}

.jh-table thead tr:last-child th {
  border-bottom: 2px solid var(--border-color);
}

.jh-table tbody tr:first-child td {
  border-top: none;
}

.jh-table-header-tile {
  text-align: left;
}

.jh-table td,
.jh-table th {
  text-align: center;
}

.jh-table .jh-table-row-header {
  text-align: right;
  padding-right: 1.5em;
  min-width: 8em;
  font-weight: 600;
  border: none;
}

.jh-normal {
  font-weight: normal !important;
}

.jh-top-border {
  border-top-color: #000 !important;
}

.jh-number-column {
  text-align: right !important;
}

.jh-total-row {
  text-align: right !important;
}

.jh-table-row-bucket {
  vertical-align: middle !important;
  font-weight: 600;
}
tr:first-of-type .jh-table-row-bucket {
  border-top: none;
}

.jh-table-col-header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.jh-table-col-header-container svg {
  align-self: center;
}

.jh-table-col-header-display {
  margin-right: 0.5rem;
}

.jh-chart-content-with-dimension-filter,
.jh-chart-content-without-dimension-filter {
  border-radius: var(--border-radius);
}

tr.row-linked td {
  position: relative;
}

a.row-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-decoration: none;
  color: inherit;
}
