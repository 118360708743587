input.placeholder-right::placeholder {
  text-align: right;
  font-style: italic;
}

/* This is apparently needed for M$ Edge.. */
input.placeholder-right::-webkit-input-placeholder {
  text-align: right;
  font-style: italic;
}
