.generic-report-table {
  position: relative;
  /* width: max-content; makes everything super big */
  min-width: 100% !important;
  height: max-content;
  font-size: 14px;
}

.generic-report-table.loading table {
  opacity: 0.5;
}

.generic-report-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.generic-report-table thead th {
  background-color: var(--main-bg-color);
  padding: 4px 5px;
  vertical-align: bottom;
  line-height: 1.2;
  position: sticky;
  white-space: nowrap;
  top: var(--table-header-offset);
}

.generic-report-table.skeletonized thead th {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@media only screen {
  .report-page {
    --table-header-offset: calc(
      var(--crumbar-height) + var(--tabbar-height) + 1rem
    );
  }

  .regional-director-page,
  .insight-data-page {
    --table-header-offset: calc(var(--crumbar-height) + 1rem);

    thead tr th {
      background-color: var(--main-bg-color);
    }

    tbody tr.Overall td {
      background-color: var(--main-bg-color);
    }
  }
}

.modal-content .generic-report-table thead th {
  background-color: white;
}

.generic-report-table thead th.Selected,
.generic-report-table tbody td.Selected {
  background-color: var(--table-selection);
}

.jh-chart-content .generic-report-table thead th,
.jh-chart-content-without-heading .generic-report-table thead th {
  background-color: var(--card-bg-color);
}

.generic-report-table thead tr:nth-child(2) th {
  top: calc(var(--table-header-offset) + 1.2em + 7px); /* kinda sorta */
}

.generic-report-table thead tr:nth-child(3) th {
  top: calc(var(--table-header-offset) + 2.4em + 14px); /* kinda sorta */
}

.generic-report-table:not(.pivot) thead th {
  cursor: pointer;
}

.generic-report-table.editing thead th {
  user-select: none; /* to prevent shift column selection extension from selecting text */
}

.generic-report-table thead.Bucketed th.Bucket:not(:first-child),
.generic-report-table thead.Bucketed th.BucketStart:not(:first-child) {
  border-left: 1px solid #ddd;
}

/** a normal border would scroll away with border-collapse and sticky header */
.generic-report-table thead tr:last-child th {
  box-shadow: inset 0 -1px 0 black;
}

@media print {
  /** but the box shadow border doesn't print. */
  .generic-report-table thead tr:last-child th {
    border-bottom: 1px solid black;
  }
}

/** This bizarreness is because using :after causes chrome to lay things out super weirdly. */
.generic-report-table .Sorter {
  position: absolute;
  right: 0.15rem;
  bottom: 0.15rem;
  color: gray;
  display: block;
  transition: transform 0.2s linear;
}

.generic-report-table thead th.Sorted.Ascending .Sorter {
  transform: rotate(180deg);
}

.generic-report-table td {
  padding: 1px 5px;
  border-left: 1px solid #ddd;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
}

.generic-report-table tbody tr:first-child td {
  padding-top: 5px;
}

.generic-report-table.compact thead th {
  /* this should probably be on always, but if a th wraps to more than one line then the sticky header renders wrongly */
  white-space: nowrap;
}

.generic-report-table.pivot tr th:first-child,
.generic-report-table.pivot tr td.Header {
  border-right: 1px solid black;
}

.generic-report-table.pivot td.Bucket {
  border-right: 1px solid #ddd;
}

.generic-report-table.row-borders tr td {
  border-top: 1px solid #eee;
}

.generic-report-table.row-borders tr:first-child td {
  border-top: none;
}

.generic-report-table td:first-child {
  border-left: none;
}

.generic-report-table th.Bucket {
  text-align: center;
  vertical-align: top;
}

.generic-report-table th.Header,
.generic-report-table th.Number,
.generic-report-table td.Number,
.generic-report-table th.Date,
.generic-report-table td.Date,
.generic-report-table th.NoValue {
  text-align: right;
}

.generic-report-table th.String {
  text-align: left;
}

.generic-report-table.pivot thead th:first-child {
  /* in pivot, the first header is a label for the row to the right of it. */
  text-align: right;
}

.generic-report-table td.Group.String {
  font-weight: bold;
}

.generic-report-table td.Group {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  /*background-color: #eee;*/
}

.generic-report-table.properties-table.grouping-Inline tr td.Group {
  border: none;
  background-color: var(--main-bg-color);
}

.generic-report-table.grouping-Inline tr:first-child td.Group {
  border-top: none;
}

.generic-report-table.grouping-Inline tr.Grouped td:first-child {
  padding-left: 1rem;
}

.generic-report-table tr.NoData td {
  text-align: center;
}

.generic-report-table td.Overall {
  font-weight: bold;
  text-align: right;
  border-top: 1px solid black;
  border-left: none;
}

/* special behavior for the first column in the Overall row */
.generic-report-table td.Overall:first-child {
  padding-right: 10px;
}

.generic-report-table.pivot thead.Bucketed th.Bucket,
.generic-report-table.pivot thead.Bucketed th.BucketStart,
.generic-report-table.pivot tbody.Bucketed td.BucketStart {
  border-left: 1px solid #aaa;
}

.generic-report-table.pivot thead:not(.Bucketed) th.Overall,
.generic-report-table.pivot thead.Bucketed tr.Buckets th.Overall,
.generic-report-table.pivot thead.Bucketed tr th.Overall.BucketStart,
.generic-report-table.pivot tbody td.Overall.BucketStart {
  border-left: 1px solid black;
}

.generic-report-table.pivot th.Group {
  border-left: 1px solid black;
}

.generic-report-table.pivot.grouping-Inline th.Group {
  border-right: 1px solid #ddd;
}

.generic-report-table.pivot td.Group {
  border-left: 1px solid black;
  border-top: none;
  border-bottom: none;
}

.generic-report-table.grouping-Inline.pivot th.Normal,
.generic-report-table.grouping-Footer.pivot th.Normal {
  font-weight: normal;
}

.generic-report-table.pivot th.Overall,
.generic-report-table.pivot td.Overall {
  border-left: 1px solid #ddd;
}

.generic-report-table.pivot td.Overall,
.generic-report-table.pivot th.Overall {
  border-top: none;
}

.generic-report-table.compact {
  font-size: 0.75rem;
}

.generic-report-table.compact td {
  padding-top: 0;
  padding-bottom: 0;
}

.generic-report-table td.Overall.Number {
  font-weight: 600;
}

.generic-report-table-header {
  display: none; /* for now consider this duplicative of print header */
  margin-bottom: 1rem;
}

.generic-report-table-title {
  color: var(--main-text-color);
  margin-left: 25px;
  font-weight: 450;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  margin-top: -25px;
  white-space: normal;
}

.chart-table-tooltip {
  max-width: 30rem;
}

.jh-revealer-row {
  margin-bottom: 0.5rem;
}

.jh-revealer-label {
  cursor: pointer;
}

.jh-revealer svg {
  transform: rotate(0deg);
  transition: transform linear var(--transition-time);
}

.jh-revealer.open svg {
  transform: rotate(90deg);
}

.insight-option:before,
.common-option:before,
.goal-option:before,
.custom-option:before,
.property-option:before,
.mortgage-option:before,
.mortgage-date-option:before,
.property-custom-column-option:before,
.property-metadata-option:before,
.date-option:before,
.dimension-option:before,
.actual-option:before,
.budget-option:before,
.proForma-option:before,
.unit-lease-option.menu:before,
.lease-option:before {
  text-transform: uppercase;
  color: #999;
  font-size: 0.8rem;
  line-height: 1.5rem;
  margin-right: 0.375rem;
  margin-left: 0.5rem;
  float: right;
}

.unit-option:before {
  text-transform: uppercase;
  color: #999;
  font-size: 0.8rem;
  line-height: 1.5rem;
  margin-right: 0.375rem;
  margin-left: 0.5rem;
  float: right;
}

.unit-status-option:before {
  text-transform: uppercase;
  color: #999;
  font-size: 0.8rem;
  line-height: 1.5rem;
  margin-right: 0.375rem;
  margin-left: 0.5rem;
  float: right;
}

.insight-option.menu:before {
  content: "Insight";
}

.common-option.menu:before {
  content: "CMQ";
}

.goal-option.menu:before {
  content: "Goal";
}

.custom-option.menu:before {
  content: "Custom";
}

.property-option.menu:before {
  content: "Property";
}

.mortgage-option.menu:before {
  content: "Mortgage";
}

.mortgage-date-option.menu:before {
  content: "Date";
}

.property-custom-column-option.menu:before {
  content: "Property (Custom Column)";
}

.property-metadata-option.menu:before {
  content: "Property (Metadata)";
}

.date-option.menu:before {
  content: "Date";
}

.dimension-option.menu:before {
  content: "Dimension";
}

.actual-option.menu:before {
  content: "Actual";
}

.budget-option.menu:before {
  content: "Budget";
}

.proForma-option.menu:before {
  content: "Pro Forma";
}

.lease-option.menu:before {
  content: "Lease Details";
}

.unit-option.menu:before {
  content: "Unit";
}

.unit-status-option.menu:before {
  content: "Unit Status";
}

.unit-lease-option.menu:before {
  content: "Lease Details";
}

@media print {
  .generic-report-table {
    max-height: inherit;
    width: 100%;
  }

  .generic-report-table td {
    print-color-adjust: exact; /* so the background colours are preserved */
  }
}
