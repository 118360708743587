.jh-card,
.jh-card .card-footer {
  transition:
    transform 0.1s,
    box-shadow 0.1s;
  background: var(--card-bg-color);
}

.jh-card > .card-header {
  padding-left: 1rem !important;
}

.jh-card.card {
  margin-bottom: 1rem;
  margin-right: 1rem;
  border: none;
}

.jh-card.card,
.jh-card.card:hover {
  color: var(--main-text-color);
}

.jh-card.card,
.jh-card-width {
  box-sizing: border-box;
  width: calc(33.3% - 1rem);
}

.jh-card:hover {
  transform: translate3d(0, -0.25rem, -0.25rem);
  box-shadow: var(--shadow);
}

.jh-card-icon {
  align-self: center;
  padding-top: 0.5rem;
}

.jh-card-title {
  font-weight: bolder;
  margin-bottom: 0 !important;
  color: var(--main-text-color) !important;
  text-decoration: none !important;
}

a.jh-card-title::after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.jh-card .card-footer {
  justify-content: space-between;
}

.jh-card .card-header {
  background-color: transparent;
  border: none;
  padding: 0.75rem 1.25rem 0;
}

.jh-card .card-icon {
  pointer-events: none;
  height: 100%;
  padding: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
}

.jh-card .card-hover-actions {
  z-index: 1;
}

.jh-card .card-hover-actions button.disabled {
  border: none;
}

.jh-card .card-body {
  padding: 0 1rem 0 0;
}

@media only screen and (max-width: 1220px) {
  .jh-card,
  .jh-card-width {
    width: calc(50% - 1rem) !important;
  }
}

@media only screen and (max-width: 800px) {
  .jh-card,
  .jh-card-width {
    width: calc(100% - 1rem) !important;
  }
}
