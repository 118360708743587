.jh-table-wrapper {
  position: relative;
  border-radius: var(--border-radius);
}

.jh-table-wrapper.td-middle td {
  vertical-align: middle;
}

.jh-with-selection-table {
  background-color: var(--card-bg-color);
  margin-bottom: 0;
  overflow: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.jh-with-selection-table thead th {
  white-space: nowrap;
  position: sticky;
  top: 0;
  border: 0;
  border-bottom: 1px solid black;
  background-color: var(--main-bg-color);
}

.jh-with-selection-table tbody tr:first-child td {
  border-top: none;
}

.jh-table-with-selection-row:hover td {
  background-color: var(--highlight-color-faded);
}

.jh-table-with-selection-row-selected td {
  background-color: var(--highlight-color);
}

.jh-table-with-selection-row,
.jh-table-with-selection-row-selected {
  cursor: pointer;
}

.jh-table-with-selection-no-results {
  text-align: center;
}
