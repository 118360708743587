.note-icon {
  line-height: 1;
  margin-right: -0.5rem;
  width: 32px;
  right: 0px;
  top: -10px;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  border: 0 !important;
}

.note {
  border: unset;
  resize: none;
  padding: unset;
}

.note-write {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding: 0.5rem 0.75rem;
}

.note-write:focus {
  border-color: #868aa9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 21, 83, 0.25);
}

.note-readonly:focus {
  box-shadow: unset;
}

.note-outline:focus-visible {
  outline: unset;
}
