.error-page-main {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.error-page-main h1 {
  font-size: 8rem !important;
  color: #333 !important;
}

.error-page-main p {
  font-size: 1.5rem;
  margin: 2rem 2rem 3rem;
  text-align: center;
}

.error-page-actions > *:not(:last-child) {
  margin-right: 4rem;
}

.error-page-actions button svg,
.error-page-actions a svg {
  color: #333;
  font-size: 8rem;
  transition: color 0.1s ease-in;
}

.error-page-actions button:hover svg,
.error-page-actions a:hover svg {
  color: var(--primary-color) !important;
}

.hero-error-wrapper {
  overflow: hidden;
}

.hero-error {
  position: absolute;
  left: 20vw;
  top: 20vh;
  width: 60vw !important;
  height: 60vh;
  z-index: 0;
  color: var(--primary-color);
  opacity: 0.1;
  transform: rotate(0deg);
  transform-origin: 120vw 50vh;
  animation: rotate linear infinite 600s;
  animation-delay: 10s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .error-page-main h1 {
    font-size: 6rem !important;
  }

  .error-page-actions > *:not(:last-child) {
    margin-right: 2rem;
  }

  .error-page-actions button svg,
  .error-page-actions a svg {
    font-size: 4rem;
  }
}
