.session-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  height: 100vh;
  width: calc(100vw - var(--scrollbar-width));
  background-image: url("splash.png");
  background-position: bottom;
  background-repeat: repeat-x;
}

.session-messages {
  display: inline-block;
  text-decoration: none !important;
  padding: 8rem;
  margin-bottom: 3.5rem;
}

.session-stuff {
  height: 0;
}

.session-message {
  font-size: 120%;
  padding-top: 1rem;
  color: black !important;
  display: inline-block;
  text-decoration: none !important;
}

#session-logo {
  width: 60vw;
  height: auto;
  max-width: 480px;
  transform: translate(8%, 0);
}

.login-button {
  margin-top: 5rem !important;
  opacity: 0;
  animation: login-fade-in ease-in 0.3s forwards;
}

@keyframes login-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
