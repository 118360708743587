.select-vendor-header {
  margin-bottom: 1rem;
}

.select-vendor-deck {
  display: flex;
}

.select-vendor-card {
  width: 200px;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 2rem 1rem;
}

.select-vendor-card-selected,
.select-vendor-card:hover {
  border-color: #c808;
}

.select-vendor-card > * {
  width: 90%;
}

.secret-blur:not(:focus) {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0);
}

.modal-content .modal-body .form-group:last-child {
  margin-bottom: 0;
}

.input-group-append select.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #eee;
}

.form-check-label > .custom-checkbox.custom-control-inline {
  vertical-align: bottom;
}

.hover-option {
  transition: background 60ms;
}

.hover-option:hover {
  background: #deebff;
}

.finance-modal-account-tree {
  margin-bottom: 1rem;
}

.finance-modal-account-tree .account-header {
  font-weight: 600;
}

.finance-modal-account-tree .account-entry {
  padding-left: 1.5rem;
}

.finance-modal-account-tree .account-revealer {
  margin: 0 0.25rem 0 -1.25rem;
  width: 1rem;
  height: 1rem;
  line-height: 1;
  text-align: center;
  display: inline-block;
  transition: transform linear 0.1s;
  transform: rotate(0);
  transform-origin: center;
  border: none;
  padding: 0;
  background: none !important;
  color: var(--main-text-color) !important;
}

.finance-modal-account-tree .account-open .account-revealer {
  transform: rotate(90deg);
}

.finance-modal-account-tree .account-line {
  margin: 0 0.5rem;
  border-bottom: 1px dashed lightgray;
  min-width: 1rem;
}

.finance-modal-account-tree .account-codes {
  text-align: right;
  font-weight: normal;
}

.finance-modal-account-tree .account-unmapped {
  color: #999;
  font-weight: 0200;
}

.finance-modal-account-tree-header {
  border-bottom: 1px solid black;
  margin-bottom: 0.25rem;
  align-items: baseline;
}

.account-search-icon {
  cursor: pointer;
  margin-bottom: 0;
}

.account-search-field {
  margin: 0 0.25rem 0 0.25rem;
  padding: 0 0.25rem;
  border: none;
  border-radius: 0;
  line-height: 1.2;
  width: 0rem;
  transition:
    margin,
    width linear 0.1s;
  height: 1.2rem;
  display: inline-block;
  vertical-align: baseline;
}

.account-label:hover {
  background-color: var(--highlight-color-faded);
}

.account-label.account-found {
  background-color: var(--highlight-color);
}

.account-found .account-label {
  background-color: #ff9;
}

.account-label-name {
  white-space: nowrap;
}

.account-search-field:focus,
.account-search-field.valuable {
  margin-left: 1rem;
  width: 10rem;
}

.finance-modal-unmapped-accounts {
  color: #960;
}

.account-dropdown {
  display: block;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  color: var(--main-text-color) !important;
  background: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.popup-account-list {
  max-height: 16rem;
  overflow: auto;

  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 10px;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: 9px;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d1553;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
}
