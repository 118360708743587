.jh-action-bar {
  justify-content: space-between;
  background-color: var(--main-bg-color);
  position: sticky;
  left: 0;
  top: var(--crumbar-height);
  height: var(--actionbar-height);
  width: calc(100vw - var(--scrollbar-width));
  z-index: 2;
}

.jh-page-content .jh-action-bar {
  padding: 0 !important;
  position: inherit;
  height: auto;
  width: auto;
  margin-bottom: 1rem;
}
