.jh-chart-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--card-bg-color);
}

.jh-chart-wrapper.clickable {
  cursor: pointer;
  text-decoration: none;
  color: var(--main-text-color);
}

.jh-chart-header {
  height: 60px;
  font-size: 1rem;
  padding: 0 1.25rem;
  font-weight: 600;
  line-height: 1.1;
}

.jh-chart-header-more-btn {
  cursor: pointer;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.jh-chart-info {
  --border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 24px;
  width: 24px;
  color: var(--primary-color);
  border-style: solid;
  border-color: var(--main-bg-color);
  border-width: var(--border-width) 0 0 var(--border-width);
  border-radius: 80% 0 2px 0;
}

.jh-chart-info .fa-info {
  margin-left: 4px;
  margin-top: 3px;
}

.jh-chart-content {
  height: calc(100% - 60px);
  padding: 0 1rem 1rem;
  width: 100%;
  overflow: auto;
}

.jh-chart-content-without-heading {
  height: 100%;
  padding: 1rem;
  width: 100%;
}

.no-padding {
  padding: 0;
}

.jh-chart-content-with-dimension-filter {
  height: calc(100% - 52px);
  width: 100%;
}

.jh-chart-content-without-dimension-filter {
  height: 100%;
  width: 100%;
}

.jh-chart-content-no-scrollbars {
  overflow: hidden !important;
}

.jh-chart-filters {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jh-chart-filters > div:not(:first-child) {
  margin-left: 1rem;
}

.jh-chart-content .vega-embed svg g.root > g > path.background {
  stroke: #ccc !important;
}
