.property-connect-main {
  margin: 2rem 4rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.property-connect-main h1 {
  text-align: center;
  margin: 0.5rem 0;
}

.property-connect-main .logo-container {
  text-transform: uppercase;
  font-family: "Noto Sans", sans-serif;
  font-size: 36px;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 2px;
  padding-left: 1.5rem;
  letter-spacing: 6px;
}
.property-connect-main .logo-prop {
  color: #555;
}
.property-connect-main .logo-pipe {
  color: black;
}
.property-connect-main .logo-connect {
  color: #c80c;
}

.property-connect-connections-container {
  margin-top: 2rem;
  padding: 1.75rem 2rem 2rem;
  background-color: #fff;
  width: 100%;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.25rem;
  min-height: 25vh;
}

.property-connect-connections-none {
  flex: 1;
  margin: 1rem 0 3.5rem;
}

.property-connect-connector-types-container {
  margin-top: 3rem;
}

.property-connect-connector-types {
  display: flex;
  flex-direction: row;
  margin-right: -1rem;
}

.property-connect-connector-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.25rem;
  background-color: #fff;
  cursor: pointer;
  padding: 2rem;
  width: calc(33.33% - 1rem);
  margin-bottom: 1rem;
  transition: all 0.1s ease-in;
}

.property-connect-connector-type:hover {
  background: #eaf8f6 !important;
  color: #c80a;
}

.property-connect-connector-type:not(:first-of-type) {
  margin-left: 1rem;
}

.property-connect-connector-type img {
  max-height: 35px;
  max-width: 15rem;
}

.property-connect-connections-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1200px) {
  .property-connect-connector-type {
    max-height: 35px;
    padding: 15px;
  }

  .property-connect-connector-type img {
    max-height: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .property-connect-connector-types {
    flex-direction: column !important;
  }

  .property-connect-connector-type:not(:first-of-type) {
    margin-left: 0;
  }

  .property-connect-connector-type {
    width: 100%;
  }
}

@media only screen and (max-width: 827px) {
  .property-connect-connections-list {
    justify-content: center;
  }

  .property-connect-connections-list-header {
    text-align: center;
  }
}
