.dashboard-insight-main {
  display: flex;
  flex-direction: column;
}

.dashboard-insight-chart-wrapper {
  margin-bottom: 1rem;
  height: calc(50vh - 4rem);
}

.dashboard-insight-data-table-container
  .jh-chart-content-without-dimension-filter {
  margin-top: 0;
  margin-bottom: 0;
  max-height: calc(50vh - 4rem);
}

.dashboard-insight-data-table {
  box-shadow: inset 0px -1px 0px rgba(207, 218, 227, 0.6);
  background: var(--card-bg-color);
  margin-bottom: 0;
}

.dashboard-insight-drill-down .jh-chart-wrapper {
  box-shadow: none;
}

.dashboard-insight-drill-down .jh-chart-header {
  font-size: 20px;
}

.dashboard-insight-footnote {
  font-size: 0.85rem;
  margin-top: 0.5rem;
  color: #333;
}
