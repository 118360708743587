.property-connect-connections-list {
  margin: 1rem -2rem -2rem 0;
}

.pc-connection-card {
  text-align: center;
  width: calc(33.33% - 2rem);
  margin-right: 2rem;
  margin-bottom: 2rem;
  background-color: white;
  border-color: black;
  text-decoration: none !important;
}

.pc-connection-card:hover {
  background-color: var(--primary-color-faded);
}

.pc-connection-card:not(:hover) {
  color: var(--main-text-color) !important;
}

.pc-connection-card:not(:hover) .jh-action-bar {
  display: none !important;
}

.pc-connection-card-header {
  font-weight: bold;
  font-size: 20px;
}

.pc-connection-card img {
  height: 1.5rem;
  opacity: 0.7;
  margin: 0 0 0 auto;
  mix-blend-mode: multiply;
}
