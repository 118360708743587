// Bootstrap variables
$primary: #0d1553;
$light: #fff;
$headings-color: #3f4254;
$btn-border-radius: 0;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;
$border-radius: 10px;
$custom-select-border-radius: $btn-border-radius;
$input-padding-x: 0.75rem;
$input-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-x-sm: 0.75rem;
$popover-max-width: auto;
$modal-max-width: auto;
$progress-height: 2.5em;
$progress-border-radius: 2em;
$card-border-width: 0;
$table-color: #404253;
$table-hover-bg: #d9ebe8;

@import "~bootstrap/scss/bootstrap.scss";
@import "../common/table/jhTable.css";

@import "~react-date-range/dist/styles.css";
@import "~react-date-range/dist/theme/default.css";

@import "quill/dist/quill.snow.css";

@import "react-calendar/dist/Calendar.css";
@import "react-date-picker/dist/DatePicker.css";

:root {
  --bs-tertiary-bg: #d9ebe8;
}

.jh-icon-with-text {
  margin-right: 0.5rem;
}

.jh-pull-right {
  margin-left: auto;
}

.jh-transparent-button {
  background-color: white !important;
  color: black !important;
  border: none !important;
}

.jh-transparent-button:focus {
  box-shadow: none !important;
}

.jh-font-color-success {
  color: var(--success-color);
}

.jh-font-color-danger {
  color: var(--danger-color);
}

.jh-block {
  display: block !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-row .pull-right {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.rounded-group > .input-group-prepend > .input-group-text {
  border-top-left-radius: 1.125rem !important;
  border-bottom-left-radius: 1.125rem !important;
}

.rounded-group > input:last-child {
  border-top-right-radius: 1.125rem !important;
  border-bottom-right-radius: 1.125rem !important;
}

.ms-0-first:first-child {
  margin-left: 0 !important;
}

.text-hover-danger:hover {
  color: $danger !important;
}

.input-group:focus-within {
  outline: 4px solid var(--highlight-color);
}

.input-group:focus-within input {
  border-color: #ced4da;
  box-shadow: none;
}

.bg-highlight {
  background: var(--highlight-color);
}

th {
  font-weight: 600;
}
