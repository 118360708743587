.loading-page-main {
  opacity: 0;
  animation: loading-fade-in ease-in forwards 0.5s;

  #loading-spinner {
    width: 32px;
    height: 32px;
    display: inline-block;

    position: absolute;
    left: calc(50vw - 16px - 7.5px); /*the scrollbarish*/
    top: calc(22rem);
  }
}

.logo-color.r-of-re {
  fill: #0d123b !important;
}

#loading-logo {
  width: var(--loading-logo-width);
}

@keyframes loading-fade-in {
  0%,
  23% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
