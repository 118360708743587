.test-integration-modal .modal-header svg {
  margin-left: 0.25rem;
}

.test-integration-modal-status {
  font-weight: bold;
  margin-left: 0.75rem;
}

.test-integration-modal-detail {
  max-height: 150px;
  overflow-y: auto;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.25rem;
  list-style-type: none;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
}
