.vertical-checkbox input {
  margin-left: 0;
  position: static;
}

.weekly-coffee {
  color: var(--primary-color);
  font-size: 30px;
}

.view-scheduled-emails-btn {
  cursor: pointer;
}

.scheduled-email-card {
  box-shadow: var(--shadow);
  border: none;
  transition:
    transform 0.1s,
    box-shadow 0.1s;
}

.scheduled-email-actions {
  background-color: var(--light);
}

.scheduled-email-action-icon {
  cursor: pointer;
  background: none;
  color: var(--primary-color);
  border: none;
  font-size: 24px;
}

.jh-action-icon .schedule-count {
  position: absolute;
  right: 2px;
  bottom: 2px;
  font-size: 12px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid var(--primary-color);
  background-color: var(--main-bg-color);
  transition: background-color linear 0.2s;
}

.jh-action-icon:hover .schedule-count,
.jh-action-icon:active .schedule-count,
.jh-action-icon:focus .schedule-count,
.show > .jh-action-icon .schedule-count {
  background-color: #fff;
}

.tabs-below {
  border-bottom-width: 0;
  border-top: 1px solid #dee2e6;
}

.tabs-below .nav-link {
  border: 1px solid transparent;
  border-radius: 0 0 0.25rem 0.25rem;
}

.tabs-below .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}

.tabs-below .nav-item.show .nav-link,
.tabs-below .nav-link.active {
  border-color: #fff #dee2e6 #dee2e6 #dee2e6;
}

.editor-action-bar {
  flex-direction: row;
  padding-left: 15px;
  border-bottom: 1px solid var(--primary-color);
  white-space: nowrap;
  position: sticky;
  left: 0;
  width: calc(100vw - var(--scrollbar-width));
  top: var(--crumbar-height);
}

.editor-action-bar .nav-link {
  border-bottom: none;
  border-color: #00000030;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.editor-action-bar .nav-link:hover,
.editor-action-bar .nav-link:focus {
  border-color: var(--primary-color-faded);
  background-color: var(--primary-color-faded);
}

.editor-action-bar .nav-link.active {
  background-color: var(--main-bg-color);
  border-color: var(--primary-color);
}

.editor-actions .action-edit,
.editor-actions .action-delete {
  color: #111;
  transition: color linear 0.1s;
  cursor: pointer;
}

.editor-actions .action-edit:hover {
  color: #0575d6;
}

.editor-actions .action-delete:hover {
  color: #d63005;
}

/** keep the edit-selection popover beneath modals */
.edit-selection-popper .popover {
  z-index: 1040;
}

.jh-icon-with-text.save-button {
  width: 140px;
}

.jh-icon-with-text.save-button .jh-font-color-success {
  color: white;
  font-weight: 100;
  margin-left: 0.25rem;
  font-size: 1.75rem;
}

.generic-drop {
  position: relative;
}

.generic-drop.isDragWaiting {
  border: 1px dashed black;
  border-radius: 0.3rem;
  margin-top: -1px;
  margin-bottom: -1px;
}

.generic-drop.isDragActive {
  border: 1px dashed #2196f3;
  border-radius: 0.3rem;
  margin-top: -1px;
  margin-bottom: -1px;
}

.generic-drop.isDragActive > :not(.no-hide) {
  visibility: hidden;
}

.generic-drop.isDragAccept {
  border-color: green;
  background-color: #cfc;
}

.generic-drop:before {
  font-size: 160px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.generic-drop.isDragAccept:before {
  color: #0a0;
  content: "☁";
}

.generic-drop.isDragReject {
  border-color: red;
  background-color: #fcc;
}

.generic-drop.isDragReject:before {
  color: #a00;
  content: "☂";
}

/** avoid overlapping the upload definition button */
.me-16 {
  margin-right: 16rem;
}

#vg-tooltip-element {
  font-size: 1em;
}

@media print {
  .generic-report-table-root {
    margin-top: 1rem;
  }

  .cell-link {
    text-decoration: none !important;
    color: var(--main-text-color);
  }

  .vega-embed {
    position: absolute !important;
    left: 0;
    width: 90vw;
  }
}

.sheet-tab {
  color: var(--primary-color);
  transition: color linear 0.1s;
  cursor: pointer;
}

.sheet-tab:hover {
  color: #0575d6;
}

.br-50 {
  border-radius: 50% !important;
}
