.admin-portal-container {
  color: #071023;
  column-count: 2;

  .portal-group {
    break-inside: avoid-column;
    background-color: white;
    padding: 0.75rem 1rem 0.125rem;
    margin-bottom: 1rem;

    .group-title {
      font-size: 1.2rem;
      color: black;
      border-bottom: 1px solid black;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  }

  .portal-tile {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1rem;
    break-inside: avoid-column;

    .portal-icon {
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .portal-label {
      font-size: 1rem;
      font-weight: 500;
    }

    .portal-desc {
      color: #343a40;
    }
  }
}

.admin-page:not(.page-scroll) .jh-table-wrapper {
  max-height: calc(100vh - 12.5rem);
  overflow: auto;
}

.admin-page.page-scroll .jh-table-wrapper thead th {
  position: sticky;
  top: calc(var(--crumbar-height) + var(--actionbar-height));
  z-index: 1;
  background-color: var(--main-bg-color);
}

.sticky-admin-header {
  --table-header-offset: calc(var(--crumbar-height) + var(--actionbar-height));
}

.admin-page.page-scroll.admin-page.no-action-bar .jh-table-wrapper thead th {
  top: calc(var(--crumbar-height) + 1rem);
}

.admin-section {
  border-bottom: 1px solid lightgray;
  padding: 1rem 0;
}

.admin-section h4 {
  margin-bottom: 1rem;
  color: #777;
  font-size: 1.25rem;
}

.admin-section:first-child {
  padding-top: 0;
}

.admin-section:last-child {
  border-bottom: none;
}

.admin-section .form-group:last-child {
  margin-bottom: 0;
}

.modal-gadget-header .modal-title {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
