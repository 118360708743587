.property-icon-button,
.property-icon-button:hover {
  background: transparent !important;
  color: var(--primary-btn-color) !important;
  border: none !important;
  width: 12px !important;
  height: 12px !important;
  margin-left: 5px !important;
  padding: 0 !important;
  text-align: center !important;
  box-sizing: border-box !important;
  display: inline !important;

  > svg {
    font-size: 1.375rem !important;
    margin-bottom: 12px !important;
  }
}

.property-eye-button {
  background: transparent !important;
  color: var(--secondary-btn-color) !important;
  border: none !important;
  width: 15px !important;
  height: 15px !important;
  margin-left: 12px !important;
  padding: 0 !important;
  text-align: center !important;
  box-sizing: border-box !important;
  display: inline !important;

  > svg {
    font-size: 1.375rem !important;
    margin-bottom: 12px !important;
  }
}

.property-cell-add-button {
  background: transparent !important;
  color: var(--secondary-btn-color) !important;
  font-size: small !important;
  width: 25px !important;
  height: 25px !important;
  padding: 0 !important;
  border: none !important;
  justify-content: center !important;
  align-content: center !important;

  > svg {
    font-size: 1.25rem !important;
  }
}

td .property-icon-button,
td .property-cell-add-button {
  opacity: 0;
  transition: opacity linear 0.3s;
}

td:hover .property-cell-add-button,
td:focus-within .property-cell-add-button,
td:hover .property-icon-button,
td:focus-within .property-icon-button {
  opacity: 1;
}

.lock-message-text {
  font-size: 12px;
  margin-left: 5px;
}

.property-delete-button,
.property-delete-button:hover {
  background: transparent !important;
  color: red !important;
  border: none !important;
  width: 15px !important;
  height: 15px !important;
  margin-left: 5px !important;
  margin-right: 10px !important;
  padding: 0 !important;
  text-align: center !important;
  box-sizing: border-box !important;
  display: inline !important;

  > svg {
    font-size: 1.375rem !important;
    margin-bottom: 12px !important;
  }
}

.edit-column-modal-header-component {
  border-color: transparent !important;
}

.edit-column-modal-header {
  border-bottom: 1px solid lightgray;
}

.manage-properties .jh-table-wrapper {
  max-height: calc(100vh - 15rem - var(--scrollbar-height));
  max-width: calc(100vw - 3rem);
  overflow: auto;
}
