/* stretched-link uses a psuedo element to stretch the link in the first cell
across the whole row. This leaves space at the end of the row for a dropdown. */
.report-row .stretched-link {
  &::after {
    right: 5em;
  }

  text-decoration: none;
}

.report-editor .card-title.level-1 label {
  display: none;
}

div.ace_editor.ace_autocomplete {
  width: 400px;
}

.report-add-button {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1.5rem;
}

.reports-cluster {
  position: sticky;
  left: 0;
  top: var(--crumbar-height);
}
