.print-header {
  font-size: 0.85rem;
  margin-bottom: 1rem;
  display: none;
}

.print-header-logo {
  max-height: 3rem;
}

.print-header .print-title {
  font-weight: bold;
  font-size: 1.2rem;
}

@media print {
  .print-header {
    display: block;
  }
}
