.goal-email-icon {
  background: var(--secondary-btn-color);
  color: #fff;
  border: none;
  width: calc(1.5em + 0.75rem + 10px); /* form-control height */
  height: calc(1.5em + 0.75rem + 2px); /* form-control height */
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  margin-left: 0.25rem;

  &:hover,
  &:active,
  &:focus {
    background: var(--secondary-btn-color-hover);
  }

  &:disabled,
  &:disabled:hover {
    background: lightgray;
    color: gray;
  }

  .schedule-count {
    position: absolute;
    right: 2px;
    bottom: 2px;
    font-size: 12px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    border: 1px solid var(--main-text-color);
    background-color: var(--main-bg-color);
    transition: background-color linear 0.2s;
    color: var(--main-text-color);
  }

  > svg {
    font-size: 1.375rem;
    vertical-align: -0.33rem;
    margin-right: 0.3rem;
  }
}
