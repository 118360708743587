.dashboard-container {
  display: flex;
  flex-direction: column;
}

.dashboard-add-insight-header {
  margin-bottom: 0.5rem;
}

.dashboard-add-insight-inverse-color {
  color: white !important;
}

.dashboard-content-container {
  display: flex;
  flex-direction: column;
  min-height: 8rem;
}

.dashboard-content-empty-container {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content-empty-message {
  max-width: 300px;
}

.dashboard-content-empty-message strong {
  font-size: 20px;
}

.dashboard-grid-container {
  width: 100%;
  min-height: 100%;
}

.dashboard-grid-item-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dashboard-grid-item-resize {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.dashboard-grid-item-resize:after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.dashboard-btn-secondary {
  background-color: #fff !important;
  border-color: var(--primary-color) !important;
  border-radius: 1em;
  color: var(--primary-color) !important;
  padding: 0.375rem 1rem;
}

.dashboard-btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 225, 225, 0.5) !important;
}

.dashboard-btn-secondary:hover:not(.disabled) {
  background-color: #ccc !important;
}

.dashboard-btn-secondary.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}

.dashboard-btn-secondary.transparent:focus:not(:active) {
  box-shadow: none !important;
}

#properties-picker,
#property-group-picker {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16rem;
}

.dashboard-property-dropdown-item {
  padding: 0.25rem 1.5rem;
  width: 100%;
  border: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-property-dropdown-item.comparison {
  display: flex;
  flex-direction: row;
}

.dashboard-property-dropdown-item.comparison .item {
  flex-grow: 1;
}

.comparison .group::before {
  content: "Group: ";
  color: #999;
}

.comparison .portfolio::before {
  content: "Portfolio: ";
  color: #999;
}

.dashboard-property-dropdown-item.comparison .vs {
  flex-grow: 0;
  position: initial;
}

.dashboard-property-dropdown-item.clickable {
  cursor: pointer;
}

.dashboard-property-dropdown-item.active {
  color: black;
  background-color: var(--primary-color-faded);
}

.dashboard-property-dropdown-item button {
  line-height: 0;
}

/*.dashboard-property-dropdown-item.active:hover {
  background-color: #e9ecef;
}*/

.dashboard-property-dropdown-item.bordered,
.dashboard-property-dropdown-bordered {
  border-bottom: 1px solid #eee;
}

.dashboard-property-dropdown-item.bordered:first-child {
  border-top: 1px solid #eee;
}

.dashboard-exporting-spinner {
  margin-right: 1rem;
  color: #57748d;
}

.dashboard-property-dropdown-actions {
  margin: 1rem 0.5rem 0.5rem;
}

.dashboard-property-dropdown-actions .btn {
  margin-right: 0.5rem;
}

.dashboard-property-dropdown-filter {
  width: calc(100% - 2rem) !important;
  border-radius: 2rem;
  font-size: 0.875rem;
}

.dashboard-footnote {
  margin-top: 1rem;
  margin-left: 0.5rem;
  font-size: 0.85rem;
  color: #333;
}

.dashboard-number-insight {
  height: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
}

.dashboard-number-insight strong {
  font-size: 36px;
  font-weight: 600;
}

.dashboard-sequence-insight-item.insight-positive.trend-positive strong,
.dashboard-sequence-insight-item.insight-negative.trend-negative strong,
.dashboard-number-insight.insight-positive.trend-positive,
.dashboard-number-insight.insight-negative.trend-negative {
  color: #3fb213;
}

.dashboard-sequence-insight-item.insight-positive.trend-negative strong,
.dashboard-sequence-insight-item.insight-negative.trend-positive strong,
.dashboard-number-insight.insight-positive.trend-negative,
.dashboard-number-insight.insight-negative.trend-positive {
  color: #b35454;
}

.dashboard-overlay-container {
  z-index: 10;
  display: block;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.dashboard-overlay-container-spinner {
  position: absolute;
  left: 50%;
  top: 50vh;
  margin-top: -32px;
  margin-left: -32px;
}

.dashboard-overlay-container-spinner .spinner-border {
  width: 64px;
  height: 64px;
}

.floaty-buttons {
  position: fixed;
  display: flex;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.floaty-buttons button {
  box-sizing: border-box;
  font-size: 2rem;
  border: none;
  border-radius: 2rem;
  width: 4rem;
  height: 4rem;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transform: scale(0);
  animation-delay: 0.1s;
  animation: appear ease-in forwards 0.5s;
  margin-left: 0.75rem;
}

.floaty-buttons button + button {
  animation-delay: 0.15s;
}

button.dashboard-add-insight-button {
  overflow: hidden;
  justify-content: start;
  transition: width 0.3s ease-in-out;
}

@keyframes appear {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

button.dashboard-add-insight-button svg {
  margin: 0 0.15rem;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

button.dashboard-add-insight-button span {
  font-size: 1.4rem;
  font-weight: 200;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

button.dashboard-add-insight-button:hover,
button.dashboard-add-insight-button:focus {
  width: 12.5rem;
}

button.dashboard-add-insight-button.actually-its-a-dashboard:hover,
button.dashboard-add-insight-button.actually-its-a-dashboard:focus {
  width: 15.5rem;
}

button.dashboard-add-insight-button:hover svg,
button.dashboard-add-insight-button:focus svg {
  transform: rotate(-90deg);
}

button.dashboard-add-insight-button:hover span,
button.dashboard-add-insight-button:focus span {
  opacity: 1;
}

.dashboard-sequence-row {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  justify-content: center;
  align-content: space-evenly;
}

.dashboard-sequence-insight-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5em;
}

.dashboard-sequence-item-pair {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.dashboard-sequence-insight-item-value {
  font-size: 36px;
  font-weight: 600;
}

.dashboard-sequence-insight-item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  max-width: 100%;
}

.number-with-percentage .value:not(.without-percentage) {
  align-items: flex-end;
  padding-right: 0.75rem;
}

.number-with-percentage .percentage {
  align-items: flex-start;
}

.number-with-percentage .percentage .dashboard-sequence-item-pair {
  align-items: flex-end;
}

.number-with-percentage .percentage .dashboard-sequence-insight-item-value {
  font-size: 30px;
  margin-top: 6px;
}

.number-with-percentage .percentage .dashboard-sequence-insight-item-label {
  font-size: 16px;
  margin-top: -8px;
}

.dashboard-grid-container .jh-chart-header {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.dashboard-grid-container.dashboard-loading .jh-chart-header {
  opacity: 0.5;
}

.skeleton {
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(
    -45deg,
    var(--card-bg-color),
    var(--card-bg-color) 10px,
    #f0f0f0 10px,
    #f0f0f0 20px
  );
  background-size: 200% 200%;
  animation: barberpole 120s linear infinite;
}

.skeleton-main {
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(
    -45deg,
    var(--main-bg-color),
    var(--main-bg-color) 10px,
    #f0f0f0 10px,
    #f0f0f0 20px
  );
  background-size: 200% 200%;
  animation: barberpole 120s linear infinite;
}

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}

.dashboard-viewing,
.dashboard-editing {
  width: calc(100vw - var(--scrollbar-width));
}

.dashboard-viewing .react-grid-item > .jh-chart-wrapper {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.1s ease-in;
}

.dashboard-viewing .dashboard-loaded .react-grid-item > .jh-chart-wrapper:focus,
.dashboard-viewing
  .dashboard-loaded
  .react-grid-item
  > .jh-chart-wrapper:hover {
  transform: translate3d(0, -0.25rem, -0.25rem);
  transition: transform var(--transition-time);
  box-shadow: var(--shadow);
}

@media print {
  #properties-picker,
  .dashboard-switcher {
    display: none !important;
  }

  .dashboard-container .jh-chart-wrapper {
    border: 1px solid #ccc;
  }
}

/* Portfolio overview card styles */

.home-overview {
  padding: 1rem 0;
  box-sizing: border-box;
}

.home-overview-label {
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
}

.home-overview-label-mobile {
  display: none;
}

.home-overview strong {
  font-size: 1.2rem;
  color: black;
}

.home-overview .home-overview-date {
  margin-top: 0.5rem;
  text-transform: uppercase;
  color: #555;
  font-size: 0.9rem;
}

.home-overview-item {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.home-overview-item-label-icon,
.home-overview-item-label-svg {
  color: var(--primary-color);
  stroke: var(--primary-color);
  width: 2.5rem !important;
  height: 2.5rem !important;
  margin-right: 0.75rem;
}

.occupied-overview .home-overview-item-label-svg {
  width: 3rem !important;
}

.home-overview-item strong {
  font-size: 1.2rem;
  font-weight: 0600;
}

.home-overview-item:not(:last-child) {
  border-right-style: solid;
  border-right-width: 2px;
  border-right-color: #ddd;
}

.home-overview-item-label {
  align-items: center;
  color: #555;
  font-size: 20px;
  font-weight: 300;
}

.home-overview-item .home-overview-item-label p {
  margin-bottom: 0;
}

.home-overview-item .home-overview-item-label a {
  color: #555;
}

.nav-pills .nav-item.active > .nav-link {
  color: #fff;
  background-color: var(--primary-color);
}

.card-container {
  margin-right: -1rem;
}

.recharts-wrapper {
  /* https://stackoverflow.com/questions/50891591/recharts-responsive-container-does-not-resize-correctly-in-flexbox */
  position: absolute !important;
}

@media only screen and (max-width: 950px) {
  .home-overview-label:not(.home-overview-label-mobile) {
    display: none;
  }

  .home-overview-label-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  .home-overview {
    flex-wrap: wrap;
    justify-content: center;
  }

  .home-overview-item {
    flex: 0 0 50%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border-right-style: none !important;
    justify-content: center;
  }

  .home-overview-item-container {
    min-width: 5.5rem;
  }
}

@media only screen and (max-width: 450px) {
  .home-overview-item {
    flex: 0 0 100%;
  }
}

.new-dashboard {
  min-height: 70vh;
}

.new-dashboard .generic-report-table {
  width: 100% !important;
  overflow-y: auto !important;
  max-height: 70vh !important;
}

/* one day this should be some form of embed config. */
.new-dashboard .vega-embed {
  min-height: 16rem;
}

@media only print {
  .new-dashboard .generic-report-table {
    max-height: initial !important;
    overflow-y: hidden !important;
  }

  .new-dashboard .vega-embed {
    position: static !important;
  }
}

.old-dashboard .iframe-card {
  overflow: auto;
}
