.width-input {
  text-align: center;
  height: 100%;
  font-size: 10px;
  border-radius: 0px;
}

.width-input:focus {
  box-shadow: unset;
}
