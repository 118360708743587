.property-tabbar {
  color: #0d1553;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  border: none;
}

.property-tabbar .nav-link {
  cursor: pointer;
  color: rgba(13, 21, 83, 0.5);
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: calc(0.5rem - 2px);
}

.property-tabbar .nav-link.active {
  color: #0d1553;
  border-bottom-color: #0d1553;
}

.property-tabbar .nav-item {
  margin-right: 0;
}

.property-badge-container {
  display: inline;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}

.property-comp-badge {
  background-color: #feebc8 !important;
  color: #b6a55b !important;
  padding-left: 15px;
  padding-right: 15px;
}

.property-own-badge {
  background-color: #c6f6d5 !important;
  color: #85c475 !important;
  padding-left: 15px;
  padding-right: 15px;
}
