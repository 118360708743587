.unsubscribe-main {
  margin: 2rem auto;
  max-width: 800px;
}

.unsubscribe-main .email {
  font-weight: 0600;
}

@media only screen and (max-width: 991px) {
  .unsubscribe-main {
    margin: 2rem 1rem;
  }

  .unsubscribe-main h1 {
    font-size: 30px;
  }

  .unsubscribe-main h3 {
    font-size: 24px;
  }

  .unsubscribe-main h4 {
    font-size: 20px;
  }
}
