.header-button {
  color: var(--headings-color);
}

:active.header-button {
  color: var(--headings-color) !important;
}

:hover.header-button {
  color: var(--headings-color) !important;
}

:focus-visible.header-button {
  color: var(--headings-color) !important;
}
